
export const useProductStore = defineStore('product', {
    state: () => ({
        count: localStorage.getItem('productCount') || 0
    }),
    actions: {
        setProductCount(count){
            this.count = count;
            localStorage.setItem('productCount', count);
        },
        clear(){
            this.count = 0;
            localStorage.removeItem('productCount');
        }
    }
})